export const es = {
  somethingWentWrong:
    "Algo salió mal. Por favor, inténtelo de nuevo más tarde.", // Google
  logout: "Cerrar sesión", // Google
  error: "Error", // Google
  search: "Buscar", // Google
  select: "Seleccionar", // Google
  dolLogo:
    "Logotipo oficial del Departamento de Trabajo y Desarrollo Laboral de Nueva Jersey", // Google
  njLogo: "logo del Estado de New Jersey",
  expand: "Expandir",
  collapse: "Contraer",
  header: {
    njFlagAlt: "Bandera de NJ",
    officialLabel: "Sitio web oficial del gobierno",
    officialSite: "Sitio oficial de New Jersey",
    governor: "Gobernador Phil Murphy",
    lieutenantGovernor: "Vicegobernadora Tahesha Way",
    getUpdates: "Recibir Novedades",
  },
  unemploymentInsurance: "Seguro de Desempleo",
  claimStatus: "Estado de la Solicitud",
  emailLoginButton: "Iniciar sesión con correo electrónico",
  ssnDeprecationAlert:
    "El 25 de noviembre, ya no podrás iniciar sesión con tu número de seguro social (SSN) y fecha de nacimiento (DOB). No esperes más, <1>crea una cuenta</1> con tu dirección de correo electrónico.", // Google
  ssnLoginButton: "Iniciar sesión con Número de Seguro Social",
  ssnLogin: {
    ssn: "Número de Seguro Social",
    dob: "Fecha de nacimiento",
    pleaseEnterFields:
      "Por favor, introduzca los siguientes campos para ver el estado de su solicitud.",
    matchError:
      "El Número de Seguro Social (SSN) y/o la Fecha de Nacimiento que introdujo no coinciden con ningún registro de la base de datos. Por favor, intente de nuevo.",
    forExample: "Por ejemplo:",
    exampleDate: " 28 abril 1986",
    months: {
      jan: "enero",
      feb: "febrero",
      mar: "marzo",
      apr: "abril",
      may: "mayo",
      jun: "junio",
      jul: "julio",
      aug: "agosto",
      sep: "septiembre",
      oct: "octubre",
      nov: "noviembre",
      dec: "diciembre",
    },
    month: "Mes",
    day: "Dia",
    year: "Año",
    enter: "Ingresar",
    clear: "Borrar",
  },
  loading: {
    pleaseWait: "Espere mientras comprobamos si ha iniciado sesión…",
    ifThisTakesLonger:
      "Si esto tarda más de 10 segundos, <1>haga clic aquí</1>.",
  },
  dontHaveAccount:
    "¿Aún no tiene una cuenta de correo electrónico? <1>Cree una aquí</1>",
  healthResources: {
    heading: "Recursos de salud",
    ifYouNeedHealthInsurance:
      "Si necesita un seguro médico, usted o su familia pueden ser elegibles para una cobertura gratuita o de bajo costo de NJ FamilyCare. Para obtener más información, visite <1>www.njfamilycare.org</1>",
    forMoreInfoGetCoveredNJ:
      "Para obtener más información sobre las opciones de seguro de salud, y para ver si usted califica para la ayuda financiera para reducir el costo de un plan, visite <1>Get Covered New Jersey</1>.",
    moreHealthResources:
      "<1>Aquí</1> puede encontrar más recursos sobre salud, como Salud Mental para Niños, Tratamiento y Apoyo para las Adicciones, Salud Mental y Apoyo Emocional, etc.",
  },
  noClaim: {
    heading: "No pudimos encontrar una solicitud en el sistema",
    body: "Asegúrese de que el correo electrónico que utilizó para iniciar sesión coincide con la solicitud que presentó. Si envió una solicitud recientemente, vuelva a consultar esta página mañana, debido a que nuestro sistema tarda hasta 24 horas en actualizarse.",
    fileClaimPrompt: "¿Aún no ha presentado una solicitud?",
    fileClaimLink: "Iniciar solicitud",
  },
  status: {
    ssnAbbreviation: "NSS", // Google
    dobAbbreviation: "FdN", // Google
    filed: "Presentada",
    pending: "Pendiente",
    pendingExplanation:
      "Las solicitudes tardan hasta 7 días en tramitarse. La información se actualiza cada noche.",
    claimType: {
      UI: "<0>Seguro por Desempleo</0> (máximo 26 semanas)",
      PUA: "<0>Ayuda de Desempleo por la Pandemia</0> PUA - Este programa federal finalizó el 4 de septiembre de 2021. Cualquier monto restante asociado con esta solicitud no es pagadero.",
      EUB: "<0>Beneficios de Desempleo Extendidos</0> 13 o 20 semanas de beneficios dependiendo de los permisos federales",
      PEUC: "<0>Compensación de Desempleo Extendida por la Pandemia</0> Los beneficios de PEUC están disponibles hasta el 4 de septiembre de 2021. Incluso si queda un saldo en su solicitud, no se pueden reclamar semanas después del pago del 4 de septiembre de 2021, ya que el programa finaliza con el pago de esa semana",
      WD: "<0>Desarrollo de la Fuerza Laboral</0>",
      TRA: "<0>Asignación por Reajuste Comercial</0>",
      ABDT: "<0>Beneficios Adicionales Durante la Formación</0>",
      DDU: "<0>Discapacidad Durante el Desempleo</0>",
    },
    headerGreeting: "¡Bienvenido(a) de Nuevo,",
    email: "Correo electrónico",
    idVerification: {
      title: "Verificación de ID",
      incomplete: "Incompleto",
      completedOn: "Completado el ",
      complete: "Completado",
      ariaLabelSummary: " resumen de Verificación de ID",
      mayBeNeededAlert: {
        heading: "Puede ser necesaria la Verificación de ID.me",
        body: "Si todavía no ha completado la verificación de ID.me hágalo para que su solicitud pueda avanzar.",
        button: "Comprobar Estado de Verificación",
      },
      incompleteAlert: {
        heading: "Es posible que se requiera verificación de identidad*", // Google
        body: "No podemos procesar su reclamo hasta que verifique su identidad. Asegúrese de registrarse con el mismo correo electrónico que utilizó en su solicitud para evitar demoras ({{email}}).", // Google
        button: "Verificar identidad",
      },
      errorAlert:
        "No pudimos encontrar información sobre su estado de verificación de ID.me. Por favor, actualice la página para volver a intentarlo.",
      ifAlreadyCompleted:
        "*Si ya completó esto, espere hasta 5 días hábiles para procesarlo.", // Google
      ifAlreadyCertifying:
        "*Si ya estás certificando exitosamente cada semana o recibiendo pago por un reclamo, no necesitas volver a verificar con ID.me.", // Google
      ifVerified:
        "*Si ha verificado su identidad con un reclamo anterior, inicie sesión en su cuenta ID.me existente para conectar su verificación de identidad con este reclamo actual.", // Google
      summary: {
        heading: "Verificación de ID.me",
        body: "ID.me es una herramienta utilizada para verificar su identidad luego de haber presentado una solicitud. Debería haber recibido un correo electrónico sobre cómo hacer la verificación después de la presentación.",
      },
    },
    title: "Estado de su Solicitud",
    updated:
      "El estado mostrado se actualizó ayer. Por favor, espere 24 horas para la actualización del sistema.",
    claimDate: "Fecha de Solicitud:",
    program: "Programa:",
  },
  eAdjudication: {
    error:
      "No pudimos obtener información sobre los pasos a seguir para su solicitud. Actualice la página para volver a intentarlo.",
    issue: "Asunto:",
    caseID: "ID del caso: ",
    submitAlert: {
      heading: "Presentar información antes del {{date}}",
      body: "Si no presenta la información a tiempo, es posible que no sea elegible para los beneficios.",
      submitBy: "Presentar antes de: ",
      give24hrs:
        "Si ya lo ha enviado, espere 24 horas para que el sistema se actualice.",
      button: "Presentar Información", // Google
    },
    reopenAlert: {
      heading: "Solicitud de información caducada", // Google
      body: "Recibió una solicitud para obtener más información sobre su reclamo que vencía el {{date}}. Si aún desea enviar esta información, llame al 732-761-2020.", // Google
    },
    receivedAlert: {
      heading: "Información Recibida",
      body: "Se ha recibido exitosamente su respuesta a la solicitud de información adicional sobre su solicitud. Un agente está revisando su solicitud actualizada.",
    },
  },
  selfService: {
    heading: "Opciones de Autoservicio",
    submit: "Enviar",
    error: {
      default: "Algo salió mal. Vuelva a intentarlo más tarde.",
      incorrectInfo:
        "La ID de solicitante que ha introducido no coincide con su SSN/FDN.",
      sessionTimeout:
        "Por su seguridad cerramos su sesión. Por favor <1>iniciar sesión</1> de Nuevo con su correo electrónico.",
    },
    manageClaim: {
      title: "Gestione su solicitud",
      subtitle:
        "Ver más información sobre su reclamo actual, cambiar información personal, etc.", // Google
      button: "Gestionar solicitud",
    },

    scheduleAppt: {
      title: "Programar una cita",
      subtitle:
        "Programe una cita en persona para obtener ayuda con su solicitud.",
      button: "Programar Cita",
    },
    resetPIN: {
      title: "Restablecer su PIN",
      subtitle: "Necesitará su PIN para certificar cada semana.",
      pleaseEnter:
        "Por favor, introduzca los siguientes campos para Restablecer su PIN.",
      notice24hrs: "El restablecimiento del PIN puede tardar hasta 24 horas",
      claimantID: "ID del Solicitante",
      claimantIDExplanation:
        "Puede encontrar su ID de Solicitante en los avisos impresos que ha recibido del NJDOL",
      newPIN: "Nuevo PIN",
      digits: "{{number}} digitos",
      emailExplanation:
        "Le enviaremos las instrucciones del restablecimiento aquí",
      success:
        "Ha solicitado con éxito restablecer su PIN. Por favor, espere 24 horas para su procesamiento.",
    },
    request1099: {
      title: "Descargue su documento 1099-G 2023", // Google
      subtitle: "Necesitará esto a final de año cuando declare sus impuestos.",
      viewBoxes:
        "<0>Vea los Casillas 1 y 4</0>" +
        " (Compensación por Desempleo e Impuestos federales sobre la renta retenidos) u " +
        "<2>obtenga una copia por correo electrónico</2>" +
        " de su 1099-G 2023 completo", // Google
      getEmailedCopy:
        "Obtenga una copia por correo electrónico de su formulario 1099-G completo",
      for2021or2020: "Para 2022 o 2021 1099-G, <1>haga clic aquí</1>.",
      box1: "<0>Casilla 1</0> - Compensación por desempleo",
      box4: "<0>Casilla 4</0> - Impuesto sobre la Renta Federal Retenido",
      pleaseEnter:
        "Introduzca su dirección de correo electrónico para recibir su 1099-G 2023", // Google
      success:
        "Ha solicitado con éxito su 1099-G 2023. Le enviaremos un correo electrónico en un plazo de 48 horas.", // Google
    },
    requestWaiver: {
      title: "Solicitar una solicitud de exención", // Google
      subtitle:
        "Este formulario es una solicitud formal de exoneración por un pago en exceso.",
      formalRequest:
        "Este formulario es una solicitud formal de exoneración por un pago en exceso.",
      pleaseConfirm:
        "Por favor, confirme que desea solicitar una exención de pago en exceso enviando su dirección de correo electrónico a continuación", // Google
      success:
        "Solicitó con éxito una solicitud de exención PUA/PEUC. Revise su correo electrónico para conocer los próximos pasos.", // Google
      weWillSend: "Enviaremos la solicitud aquí",
    },
  },
  issueDescription: {
    "A/A - 01 02": "Disponibilidad/capacidad para trabajar a tiempo completo",
    "ASW - 03": "Usted informó que no está buscando trabajo activamente",
    "VQ - 04": "Renunciar voluntariamente al trabajo o jubilarse",
    "MC - 05 39 55":
      "Posibles problemas de rendimiento y/o mala conducta relacionados con su trabajo",
    "RSWC - 06": "Posible rechazo de un trabajo adecuado",
    "RSWEP - 06": "El empleador informó que usted rechazó un trabajo adecuado",
    "RR - 07 22":
      "Su solicitud para reclamar beneficios por semanas adicionales no certificadas",
    "PAID AFTER LDW - 08":
      "Recibió sueldos o indemnizaciones por despido después del último día de trabajo",
    "SCHOOL EMP_S - 09":
      "Usted es un empleado de la escuela que puede volver después de un descanso",
    "EMPL_ FT-C - 10": "Empleo a tiempo completo mientras cobra beneficios",
    "EMPL_ FT-EM - 10":
      "Empleo a tiempo completo notificado por el empleador mientras usted cobraba beneficios",
    "Pension - 15": "Recibió o solicitó una pensión/jubilación",
    "SELF EMP": "Posible autoempleo",
    "CORP OFF":
      "Posible ejecutivo corporativo del empleador que figura en la solicitud",
    OTHER: "El motivo de la separación del trabajo no está claro",
    "FOREIGN IP":
      "La certificación semanal sEl estatus de estudiante puede afectar la elegibilidad",
    STUDENT2: "El estatus de estudiante puede afectar la elegibilidad",

    CV19: "La separación del trabajo puede deberse al COVID-19",
    HOURS:
      "Posible negativa a trabajar todas las horas ofrecidas por el empleador",
    "PUA-V": "Prueba de ingresos necesaria para su solicitud de PUA",
    "EMP W EARNINGS":
      "No comunicar los ingresos mientras se reciben beneficios de UI",
    DNR: "Faldí una cita programada", // Google
    SAVE: "Autorización para trabajar en los Estados Unidos de América", // Google
    "TOLL OUT":
      "Anteriormente inhabilitado para recibir prestaciones por desempleo", // Google
  },
  footer: {
    returnToTop: "Volver al principio",
    officialSite: "Identificador de agencia",
    njHome: "NJ Incio",
    agencyIdentifier: "Descripción de la agencia",
    agencyDescription: "Departamentos/Agencias",
    servicesAZ: "Servicios de la A a la Z",
    departments: "Departamentos/Agencias",
    faqs: "Preguntas Frecuentes",
    contactUs: "Contáctenos",
    privacyNotice: "Aviso de Privacidad",
    legalStatement: "Aviso Legal y Exención de Responsabilidad",
    accessibility: "Accesibilidad",
    opra: "Ley de Registros Públicos Abiertos (OPRA)",
    copyright: "Derechos de Autor",
  },
  appeals: {
    appeal: "Solicitación", // Google
    appealNumber: "Solicitación {{numericalAppealNumber}}", // Google
    appellants: {
      Claimant: "Solicitante", // Google
      Deputy: "Diputada", // Google
      Employer: "Empleador", // Google
    },
    casesFound: "{{caseCount}} casos encontrados", // Google
    decision: "Decisión", // Google
    decisionDate: "Fecha de decisión", // Google
    decisionsFound: "{{totalResultsCount}} casos encontrados", // Google
    detailsError:
      "Hubo un error al encontrar esa apelación. <1>Busque de nuevo</1>.", // Google
    disclaimer: {
      topline:
        "<0>Este recurso se proporciona únicamente con fines informativos</0> y no sustituye la investigación legal ni el asesoramiento de un abogado.", // Google
      toplineLink: "Aprende más", // Google
      infoText:
        "<0>Las leyes y reglas pueden cambiar con el tiempo.</0> Además, el resultado de un caso particular se basa en los hechos y pruebas específicos presentados durante la apelación. Por estos motivos, las decisiones que encontrará en esta base de datos no siempre se aplicará a su caso individual.", // Google
      ariaLabelSummary: " más información sobre este recurso.", // Google
    },
    dispositions: {
      D1: "Afirmado", // Google
      D2: "Modificado", // Google
      D3: "Invertido", // Google
      D4: "Devolución al diputado", // Google
      D5: "Devolución al Tribunal de Apelación", // Google
      D6: "Remisión del Tribunal Superior", // Google
      D7: "Desestimado (no compareciente)", // Google
      D8: "Desestimado Sin Prejuicio", // Google
      D9: "Descartado (Tarde)", // Google
      D10: "Desestimado (sin problema justificable)", // Google
      D11: "Retirado", // Google
      D12: "Desestimado (Denegación de reapertura)", // Google
      D13: "Descartado (Otro)", // Google
    },
    docket: "Legajo", // Google
    docketNumber: "Número de Legajo", // Google
    error: "Se produjo un error al encontrar las decisiones de apelación.", // Google
    filedBy: "Archivado por", // Google
    issue: "Asunto", // Google
    issueCodes: {
      "001": "Salida voluntaria", // Google
      "002": "Descarga por mala conducta", // Google
      "003": "Denegación de trabajo adecuado", // Google
    },
    jurisdiction: "Jurisdicción", // Google
    jurisdictions: {
      AT: "Tribunal de Apelación", // Google
      BoR: "Junta de Revisión", // Google
    },
    lastPage: "Última página", // Google
    moreThanMax: "Más de {{maximum}}", // Google
    next: "Próxima", // Google
    nextPage: "siguiente página", // Google
    noDecisionText:
      "No hay ningún texto de decisión disponible para este caso.", // Google
    page: "página {{pageNumber}}", // Google
    previous: "Previa", // Google
    previousPage: "página anterior", // Google
    searchCases: "Buscar caso(s)", // Google
    searchingFor: `Buscando: "{{searchTerm}}"`, // Google
    searchPlaceholder:
      "Consejos de búsqueda: una frase, palabra clave o un número de expediente específico", // Google
    searchTitle: "Búsqueda de casos de apelaciones de UI", // Google
    selectParty: "Seleccione una persona/parte", // Google
    selectTime: "Seleccione un rango de tiempo", // Google
    selectTopic: "Seleccione un tema específico", // Google
    showCasesAbout: "Muéstrame casos de apelaciones sobre", // Google
    timeframe: {
      oneYear: "1 año", // Google
      sixMonths: "6 meses", // Google
      thirtyDays: "30 dias", // Google
    },
    title: "Bienvenido a la <1>Herramienta de investigación de apelaciones</1>", // Google
    titlePrompt:
      "Esta es una base de datos de búsqueda de decisiones pasadas del Tribunal de Apelaciones y de la Junta de Revisión.", // Google
    unknownDate: "Fecha desconocida", // Google
    whereDecisionWas: "donde la decisión final fue a favor de la", // Google
    withinTheLast: "Dentro de la última", // Google
  },
};
