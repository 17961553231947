import { useState, useEffect } from "react";
import DecisionTableHeader from "./DecisionTableHeader";
import { Decision, DecisionSort } from "./types";
import { useTranslation } from "react-i18next";
import { getNumericalAppealNumber } from "./utils";
import { APPEALS_ROUTE } from "shared/constants";

interface DecisionTableProps {
  decisions: Decision[];
  decisionSort: DecisionSort | undefined;
  onDecisionSortChange: (decisionSort: DecisionSort | undefined) => void;
}

export default function DecisionTable({
  decisions,
  decisionSort,
  onDecisionSortChange,
}: DecisionTableProps) {
  const { i18n, t } = useTranslation();
  const [isTabletWidth, setIsTabletWidth] = useState(window.innerWidth < 690);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletWidth(window.innerWidth < 690);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSort = (field: keyof Decision) => {
    let newSort: DecisionSort | undefined;

    if (decisionSort === undefined || decisionSort.field !== field) {
      newSort = { ascending: true, field };
    } else if (decisionSort.ascending) {
      newSort = { ascending: false, field };
    }

    onDecisionSortChange(newSort);
  };

  const headerForField = (field: keyof Decision) => {
    const sortedAscending =
      decisionSort && decisionSort.field === field
        ? decisionSort.ascending
        : undefined;
    return (
      <DecisionTableHeader
        field={field}
        onSort={onSort}
        sortedAscending={sortedAscending}
      />
    );
  };

  const appealDetailsUrl = (decision: Decision) => {
    return `${APPEALS_ROUTE}/${getNumericalAppealNumber(decision)}`;
  };

  if (decisions.length === 0) {
    return null;
  }

  return (
    <div className={isTabletWidth ? "width-mobile" : ""}>
      <table className="usa-table usa-table--striped usa-table--stacked">
        <thead>
          <tr>
            {headerForField("decisionDate")}
            {headerForField("appealNumber")}
            {headerForField("appellant")}
            {headerForField("issueCodes")}
            {headerForField("dispositionCode")}
          </tr>
        </thead>
        <tbody>
          {decisions.map((appealsDecision) => (
            <tr key={appealsDecision.appealNumber}>
              <td
                data-sort-active={
                  decisionSort?.field === "decisionDate" || undefined
                }
                data-label={t("appeals.decisionDate")}
              >
                {appealsDecision.decisionDate
                  ? new Date(appealsDecision.decisionDate).toLocaleDateString(
                      i18n.language
                    )
                  : t("appeals.unknownDate")}
              </td>
              <td
                data-sort-active={
                  decisionSort?.field === "appealNumber" || undefined
                }
                data-label={t("appeals.appeal")}
              >
                <a
                  className="usa-link"
                  href={appealDetailsUrl(appealsDecision)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getNumericalAppealNumber(appealsDecision)}
                </a>
              </td>
              <td
                data-sort-active={
                  decisionSort?.field === "appellant" || undefined
                }
                data-label={t("appeals.filedBy")}
              >
                {t(
                  "appeals.appellants." + appealsDecision.appellant,
                  appealsDecision.appellant
                )}
              </td>
              <td
                data-sort-active={
                  decisionSort?.field === "issueCodes" || undefined
                }
                data-label={t("appeals.issue")}
              >
                {appealsDecision.issueCodes.join("; ")}
              </td>
              <td
                data-sort-active={
                  decisionSort?.field === "dispositionCode" || undefined
                }
                data-label={t("appeals.decision")}
              >
                {appealsDecision.dispositionCode
                  ? t(
                      "appeals.dispositions." +
                        appealsDecision.dispositionCode.split(".")[0],
                      appealsDecision.dispositionCode
                    )
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
