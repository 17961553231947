import icons from "@newjersey/njwds/dist/img/sprite.svg";
import { useTranslation } from "react-i18next";
import { logGA4Event } from "../shared/analytics";

const MAX_RESULTS_COUNT = 10000;

interface DecisionTablePaginationProps {
  aboveTable: boolean;
  decisionsPerPage: number;
  decisionTablePage: number;
  onPageChange: (page: number) => void;
  searchTerm: string;
  totalResultsCount: number;
}

export default function DecisionTablePagination({ aboveTable, decisionsPerPage, decisionTablePage, onPageChange, searchTerm, totalResultsCount }: DecisionTablePaginationProps) {
  const { t } = useTranslation();
  const lastPageIndex = Math.ceil(totalResultsCount / decisionsPerPage) - 1;
  const paginationItems = [];

  const onPageClicked = (page: number) => {
    onPageChange(page);
    logGA4Event(
      "Appeals pagination clicked",
      { event_label: lastPageIndex.toString(), event_details: page.toString() }
    );
  };

  if (decisionTablePage > 0) {
    // Previous button
    const previousLabel = t("appeals.previous_page");
    paginationItems.push(
      <li
        className="usa-pagination__item usa-pagination__arrow"
        key={previousLabel}
      >
        <a
          href="#previous-page"
          className="usa-pagination__link usa-pagination__previous-page"
          aria-label={previousLabel}
          onClick={(e) => {
            e.preventDefault();
            onPageClicked(decisionTablePage - 1)
          }}
        >
          <svg className="usa-icon" aria-hidden="true" role="img">
            <use xlinkHref={`${icons}#navigate_before`} />
          </svg>
          <span className="usa-pagination__link-text">{t("appeals.previous")}</span>
        </a>
      </li>
    );

    // Link to first page
    const firstLabel = t("appeals.page", { pageNumber: 1 });
    paginationItems.push(
      <li
        className="usa-pagination__item usa-pagination__page-no"
        key={firstLabel}
      >
        <a
          href="#page-1"
          className="usa-pagination__button"
          aria-label={firstLabel}
          onClick={(e) => {
            e.preventDefault();
            onPageClicked(0);
          }}
        >
          1
        </a>
      </li>
    );
  }

  // Ellipsis before current page
  if (decisionTablePage > 2) {
    paginationItems.push(
      <li
        className="usa-pagination__item usa-pagination__overflow"
        aria-label="ellipsis indicating non-visible pages"
        key="before current page"
      >
        <span>...</span>
      </li>
    );
  }

  // One page before current page
  if (decisionTablePage > 1) {
    const oneBeforeLabel = t("appeals.page", { pageNumber: decisionTablePage });
    paginationItems.push(
      <li
        className="usa-pagination__item usa-pagination__page-no"
        key={oneBeforeLabel}
      >
        <a
          href={`#page-${decisionTablePage}`}
          className="usa-pagination__button"
          aria-label={oneBeforeLabel}
          onClick={(e) => {
            e.preventDefault();
            onPageClicked(decisionTablePage - 1)
          }}
        >
          {decisionTablePage}
        </a>
      </li>
    );
  }

  // Current page
  const currentLabel = t("appeals.page", { pageNumber: decisionTablePage + 1 });
  paginationItems.push(
    <li
      className="usa-pagination__item usa-pagination__page-no"
      key={currentLabel}
    >
      <a
        href={`#page-${decisionTablePage + 1}`}
        className="usa-pagination__button usa-current"
        aria-label={currentLabel}
        aria-current="page"
        onClick={(e) => e.preventDefault()}
      >
        {decisionTablePage + 1}
      </a>
    </li>
  );

  // One page after current page
  if (decisionTablePage < lastPageIndex - 1) {
    const oneAfterLabel = t("appeals.page", { pageNumber: decisionTablePage + 2 });
    paginationItems.push(
      <li
        className="usa-pagination__item usa-pagination__page-no"
        key={oneAfterLabel}
      >
        <a
          href={`#page-${decisionTablePage + 2}`}
          className="usa-pagination__button"
          aria-label={oneAfterLabel}
          onClick={(e) => {
            e.preventDefault();
            onPageClicked(decisionTablePage + 1)
          }}
        >
          {decisionTablePage + 2}
        </a>
      </li>
    );
  }

  // Ellipsis after current page
  if (decisionTablePage < lastPageIndex - 2) {
    paginationItems.push(
      <li
        className="usa-pagination__item usa-pagination__overflow"
        aria-label="ellipsis indicating non-visible pages"
        key="after current page"
      >
        <span>...</span>
      </li>
    );
  }

  if (decisionTablePage < lastPageIndex) {

    // Last page
    if (!searchTerm || totalResultsCount < MAX_RESULTS_COUNT) {
      const lastLabel = t("appeals.lastPage") + ", " + t("appeals.page", { pageNumber: lastPageIndex + 1 });
      paginationItems.push(
        <li
          className="usa-pagination__item usa-pagination__page-no"
          key={lastLabel}
        >
          <a
            href={`#page-${lastPageIndex + 1}`}
            className="usa-pagination__button"
            aria-label={lastLabel}
            onClick={(e) => {
              e.preventDefault();
              onPageClicked(lastPageIndex)
            }}
          >
            {lastPageIndex + 1}
          </a>
        </li>
      );
    }

    // Next button
    const nextLabel = t("appeals.next_page");
    paginationItems.push(
      <li
        className="usa-pagination__item usa-pagination__arrow"
        key={nextLabel}
      >
        <a
          href="#next-page"
          className="usa-pagination__link usa-pagination__next-page"
          aria-label={nextLabel}
          onClick={(e) => {
            e.preventDefault();
            onPageClicked(decisionTablePage + 1)
          }}
        >
          <span className="usa-pagination__link-text">{t("appeals.next")} </span>
          <svg className="usa-icon" aria-hidden="true" role="img">
            <use xlinkHref={`${icons}#navigate_next`} />
          </svg>
        </a>
      </li>
    );
  }

  const totalResultsCountText = searchTerm && totalResultsCount > MAX_RESULTS_COUNT 
    ? t("appeals.moreThanMax", { maximum: new Intl.NumberFormat().format(MAX_RESULTS_COUNT) })
    : new Intl.NumberFormat().format(totalResultsCount);

  const totalResultsFoundText = t("appeals.decisionsFound", { totalResultsCount: totalResultsCountText });

  const searchText = searchTerm && searchTerm.length > 0
    ? `. ${t("appeals.searchingFor", {searchTerm})}` 
    : '';

  return (
    <div className="decision-table-pagination">
      <div className="decision-table-info">{totalResultsFoundText + searchText}</div>
      {
        lastPageIndex > 0 &&
        <nav
          aria-label={aboveTable ? "Pagination above table" : "Pagination below table"}
          className="usa-pagination"
        >
          <ul className="usa-pagination__list">
            {paginationItems}
          </ul>
        </nav>
      }
    </div>
  );
}
