const TIMEOUT_MS = 10000;
export const fetchWithTimeout = async (url: string, timeout = TIMEOUT_MS, isRetry = false): Promise<Response> => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, {
      signal: controller.signal
    });
    clearTimeout(id);
    return response;
  } catch (error) {
    clearTimeout(id);
    if (!isRetry) {
      return fetchWithTimeout(url, timeout, true);
    }
    throw error;
  }
};
