import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import icons from "@newjersey/njwds/dist/img/sprite.svg";
import { logGA4Event } from 'shared/analytics';

interface SearchBarProps {
  disabled: boolean;
  onSearch: (searchString: string) => void;
}

export default function SearchBar({ disabled, onSearch }: SearchBarProps) {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const oldSearchString = searchString;
    const newSearchString = event.target.value;
    setSearchString(newSearchString);
    if (newSearchString === "" && oldSearchString !== "") {
      onSearch(newSearchString);
    }
  };

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(searchString);

    if (searchString.length > 0) {
      logGA4Event("Appeals search", { event_label: searchString });
    }
  };
  
  return (
    <section aria-label="Small search component" className="margin-bottom-9">
      <form className="usa-search usa-search--small" role="search" onSubmit={handleSearch}>
        <label className="usa-sr-only" htmlFor="search-field-en-small">Search</label>
        <input
          className="usa-input"
          disabled={disabled}
          id="search-field-en-small"
          type="search"
          onChange={handleInputChange}
          placeholder={t("appeals.searchPlaceholder")}
          name="search"
          value={searchString}
        />
        <button
          aria-label={t("search")}
          className={`usa-button ${disabled ? 'border' : ''}`}
          disabled={disabled}
          type="submit"
        >
          <svg
            className="usa-icon"
            aria-hidden="true"
            focusable="false"
            role="img"
          >
            <use xlinkHref={`${icons}#search`}></use>
          </svg>
        </button>
      </form>
    </section>
  );
}
