export enum Appellant {
  Claimant = "Claimant",
  Deputy = "Deputy",
  Employer = "Employer",
}

export interface Decision {
  appealNumber: string;
  appellant: Appellant;
  decision?: string;
  decisionDate: Date | null;
  dispositionCode: DispositionCode | null;
  docketNumber: `DKT${string}`;
  issueCodes: string[];
  jurisdiction: Jurisdiction;
}

export interface DecisionSort {
  ascending: boolean;
  field: keyof Decision;
}

export interface FilterSet {
  appellant: Appellant | undefined;
  issueCode: string | undefined;
  timeframe: Timeframe | undefined;
}

export enum Jurisdiction {
  appealsTribunal = "AT",
  boardOfReview = "BOR",
}

export enum DispositionCode {
  Affirmed = "D1",
  Modified = "D2",
  Reversed = "D3",
  RemandToDeputy = "D4",
  RemandToAppealTribunal = "D5",
  DismissedNonAppearance = "D7",
  DismissedWithoutPrejudice = "D8",
  DismissedLate = "D9",
  DismissedNoJustifiableIssue = "D10",
  Withdrawn = "D11",
  DismissedDenialOfReopening = "D12",
  DismissedOther = "D13",
}

export enum Timeframe {
  ThirtyDays = "thirtyDays",
  SixMonths = "sixMonths",
  OneYear = "oneYear",
}
