import dolLogo from "../assets/img/dol_logo.png";
import {
  ENDPOINTS,
  FEATURE_FLAG_SHOW_APPEALS_FILTERS,
} from "../shared/constants";
import "./Appeals.css";
import { Decision, DecisionSort, FilterSet } from "./types";
import Disclaimer from "./Disclaimer";
import DecisionTable from "./DecisionTable";
import DecisionTablePagination from "./DecisionTablePagination";
import Filters from "./Filters";
import SearchBar from "./SearchBar";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import AppealsAlert from "./AppealsAlert";
import { fetchWithTimeout } from "shared/functions/fetchWithTimeout";
import { logGA4Event } from 'shared/analytics';

const APPEALS_ERROR = "Fetching appeals decisions failed";
const DECISIONS_PER_PAGE = 15;

function Appeals() {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const [decisions, setDecisions] = useState<Decision[]>([]);
  const [decisionSort, setDecisionSort] = useState<DecisionSort>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalResultsCount, setTotalResultsCount] = useState(0);

  const fetchDecisions = useCallback(async () => {
    setError(false);
    setLoading(true);
    setTotalResultsCount(0);
    setDecisions([]);

    const fetchAddress = `${ENDPOINTS.appeals.basic}?pageNumber=${currentPage}&pageSize=${DECISIONS_PER_PAGE}` + (searchTerm ? `&search=${searchTerm}`: '');

    try {
      const response = await fetchWithTimeout(fetchAddress);
      if (response.status !== 200) {
        throw new Error(APPEALS_ERROR);
      }
      const { decisions, totalResultsCount } = await response.json();
      if (decisions == null || totalResultsCount == null) {
        throw new Error(APPEALS_ERROR);
      }
      setTotalResultsCount(totalResultsCount);
      setDecisions(decisions);
      logGA4Event(
        "Appeals fetch success",
        { event_label: searchTerm, event_details: totalResultsCount }
      );
    } catch (error) {
      setError(true);
      const errorMessage = error instanceof Error ? error.message : String(error);
      logGA4Event("Appeals fetch error", { event_details: errorMessage });
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm]);

  useEffect(() => {
    fetchDecisions();
  }, [fetchDecisions]);

  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const onDecisionSortChange = (decisionSort: DecisionSort | undefined) => {
    setDecisionSort(decisionSort);
  };

  const onFilterSetSelected = (filterSet: FilterSet) => {
    fetchDecisions();
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Disclaimer />
      <main className="main-section margin-y-9">
        {error && <AppealsAlert message={t("appeals.error")} />}
        <img alt={t("dolLogo")} src={dolLogo} height={60} width={60} />
        <h1 className="text-normal">
          <Trans i18nKey={"appeals.title"} components={{ 1: <strong /> }} />
        </h1>
        <p className="text-semibold">{t("appeals.titlePrompt")}</p>
        {FEATURE_FLAG_SHOW_APPEALS_FILTERS && (
          <>
            <Filters onFilterSetSelected={onFilterSetSelected} />
            <hr className="section-divider margin-y-4" />
          </>
        )}
        <SearchBar disabled={loading} onSearch={setSearchTerm} />
        { 
          error ? null :
            loading ? (
              <div className="table-loader-container">
                <div className="loader margin-top-4" data-testid="loader" />
              </div>
            ) : (
              <>
                <DecisionTablePagination
                  aboveTable
                  decisionsPerPage={DECISIONS_PER_PAGE}
                  decisionTablePage={currentPage}
                  onPageChange={onPageChange}
                  searchTerm={searchTerm}
                  totalResultsCount={totalResultsCount}
                />
                <DecisionTable
                  decisions={decisions}
                  decisionSort={decisionSort}
                  onDecisionSortChange={onDecisionSortChange}
                />
                {
                  totalResultsCount > 0 &&
                  <DecisionTablePagination
                    aboveTable={false}
                    decisionsPerPage={DECISIONS_PER_PAGE}
                    decisionTablePage={currentPage}
                    onPageChange={onPageChange}
                    searchTerm={searchTerm}
                    totalResultsCount={totalResultsCount}
                  />
                }
              </>
            )
        }
      </main>
    </>
  );
}

export default Appeals;
